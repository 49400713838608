import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { CompanyContactNumber, RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import { phoneFormat } from '../../components/utils';

const BookingSelectedPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { facilityStore, bookingStore, customerStore } = useStore()
  const { facility } = facilityStore
  const { booking, bookTimeSlot } = bookingStore
  const [isBookingOperationProcessing, setBookingOperationProcessing] =
    useState(false)

  const data = {
    title: "Confirmation",
    primaryContent: "Confirm booking",
    secondaryContent: "Choose another time",
  }

  const handlePrimaryClick = async () => {
    //if booking has been made earlier and somehow user is able to click the book button again, check and disallow it
    if (bookingStore.bookingResult) {
      alert(
        "You have already made a booking. You will be redirected to your booking."
      )
      navigate(bookingStore.bookingResult.manageBookingUrlRelative)
      return
    }

    setBookingOperationProcessing(true)

    let bookingResult = await bookTimeSlot()

    navigate("/book/booking-confirmed")
  }

  const handleSecondaryClick = () => {
    navigate("/book/booking-selection")
  }

  useEffect(() => {
    if (bookingStore.bookingResult) {
      alert(
        "We noticed you have made a booking. Thanks! \nWe are redirecting you to your booking."
      )
      navigate(bookingStore.bookingResult.manageBookingUrlRelative)
      return
    }

    if (booking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === booking.repairType
      )

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.CarRepair} alt="Repair Type" />,
            description: (
              <EuiText grow={false}>
                Repair required
                <br />
                <strong>{repairType.name}</strong>
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    booking?.repairDate,
                    booking?.dropOffTime
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Estimated completion</strong>
                  <br />
                  {getCompletionDateUIFormatted(
                    booking?.repairDate,
                    repairType.durationInWeeks
                  )}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Phone} alt="Contact" style={{width: '42px'}} />,
            description: <EuiText grow={false}>
                <p><strong>Assistance</strong><br />
                For booking assistance call <br/><a href={'tel:' + facility?.contactDetails.replace(/ /g, '')}>{phoneFormat(facility?.contactDetails)}</a></p>
            </EuiText>
        },
        ]

        setBookingSummary([...summary])
      }
    }
  }, [])

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={85}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={
        <HailCXDoubleButtonFooter
          primaryContent={data.primaryContent}
          secondaryContent={data.secondaryContent}
          handlePrimaryClick={handlePrimaryClick}
          handleSecondaryClick={handleSecondaryClick}
          secondaryIconRequired={true}
          primaryButtonDisabled={isBookingOperationProcessing}
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Booking selected</h1>
        <p>Happy with this booking? Let's lock it in.</p>
      </EuiText>
      <EuiSpacer />
      <EuiPanel>
        <EuiDescriptionList type="column" listItems={bookingSummary} />
      </EuiPanel>
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(BookingSelectedPage)
